import { useEffect, useRef } from 'react';
import Service from 'src/services/Service';
import * as Sentry from '@sentry/react';

interface IDoctorInvoicesIframeProps {
	doctorId: string;
}

const DoctorInvoicesIframe = ({ doctorId }: IDoctorInvoicesIframeProps) => {
	// Attributes
	const iframeRef = useRef<HTMLIFrameElement>(null);

	// Effects
	useEffect(() => {
		const handleIframeLoad = () => {
			try {
				if (iframeRef.current) {
					// setTimeout is used to avoid postMessage being called before the iframe is ready
					// TODO: This is a workaround, we should find a better way to handle this
					setTimeout(() => {
						iframeRef.current.contentWindow?.postMessage(
							{ token: Service.BEARER_TOKEN },
							new URL(process.env.REACT_APP_MC_APP_BO_URL ?? '').origin
						);
					}, 500);
				}
			} catch (error) {
				Sentry.captureException(error, {
					level: Sentry.Severity.Error,
					extra: {
						doctorId,
						message: 'Error while sending token to iframe',
					},
				});
			}
		};

		const iframeElement = iframeRef.current;
		if (iframeElement) {
			iframeElement.addEventListener('load', handleIframeLoad);
		}

		return () => {
			if (iframeElement) {
				iframeElement.removeEventListener('load', handleIframeLoad);
			}
		};
	}, []);

	// Render
	return (
		<div
			style={{
				display: 'flex',
				flex: 1,
				height: '100vh',
				width: '100%',
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: '85px',
					right: '-2px',
					height: '100%',
					width: '96%',
				}}
			>
				<iframe
					ref={iframeRef}
					title='doctor-invoices'
					src={`${process.env.REACT_APP_MC_APP_BO_URL}/dashboard/doctors/invoices?doctorId=${doctorId}`}
					style={{ width: '100%', height: '100%' }}
				/>
			</div>
		</div>
	);
};
export default DoctorInvoicesIframe;
