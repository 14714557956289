import * as React from 'react';
import SMSViewStyle from './SMSViewStyle';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import SMSViewProps from './SMSViewProps';
import { Spring } from 'react-spring';
import DeliveryFileButton from './components/DeliveryFileButton/DeliveryFileButton';
import Lottie from 'lottie-react-web';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import PORButton from './components/PORButton';
import DateHelper from 'src/helpers/DateHelper';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export default class SMSView extends BaseComponent<SMSViewProps, any> {
	/* ATTRIBUTES */
	style = new SMSViewStyle();

	/* CONSTRUCTOR */
	constructor(props: any) {
		super(props);

		// Initial state
		this.state = {
			sendHovered: false,
			open: this.props.onClick !== undefined,
			response: undefined,
			isBusy: false,
		};

		// Bindings
		this.mapColors = this.mapColors.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.renderBadge = this.renderBadge.bind(this);
		this.handleHovered = this.handleHovered.bind(this);
		this.normalizeStatus = this.normalizeStatus.bind(this);
	}

	/* HANDLERS */
	async handleSend() {
		const { onClick } = this.props;

		if (onClick && !this.state.isBusy) {
			onClick(this.props.communication);
			this.setState({ isBusy: true });
		}
	}

	handleHovered() {
		this.setState((previousState) => {
			return { sendHovered: !previousState.sendHovered };
		});
	}

	/* METHODS */
	normalizeStatus() {
		if (this.props.communication.status) {
			if (this.props.communication.status === 'INTRANSIT') {
				return 'SENT';
			}
			return this.props.communication.status;
		}
		return '- - -';
	}

	mapColors() {
		const { targetType } = this.props.communication;

		switch (`${targetType}`) {
			// WORKER
			case '0':
				return { backgroundColor: '#FDB56D' };

			// COMPANY
			case '1':
				return { backgroundColor: '#9FD6E5' };

			// DOCTOR
			default:
				return { backgroundColor: '#85D8C8' };
		}
	}

	/* RENDER */
	render() {
		const fromAnimation = {
			marginLeft: window.innerWidth - 517 - 80,
			scale: 0,
		};
		const toAnimation = { marginLeft: 0, scale: 1 };

		// Sanitize and parse the message content
		const sanitizedMessage = this.props.communication
			? DOMPurify.sanitize(this.props.communication.message)
			: '';

		return (
			<Spring from={fromAnimation} to={toAnimation} delay={this.props.delay}>
				{(animatedStyle: any) => {
					return (
						<LocalizationContext.Consumer>
							{({ translate }) => (
								<div
									key={this.props.communication.id}
									style={{
										...this.style.basicLogContainer,
										transform: `translate(${
											animatedStyle.marginLeft
										}px, ${0}px)`,
									}}
								>
									<div style={this.style.basicLog}>
										{/* STAMP RENDERING */}
										{this.renderStamp(this.props.log)}

										<div
											style={{
												...this.style.commentLogText,
												...this.mapColors(),
												borderTopLeftRadius: 4,
												borderTopRightRadius: 4,
												height: 38,
												borderBottomLeftRadius: this.state.open ? 0 : 4,
												borderBottomRightRadius: this.state.open ? 0 : 4,
											}}
										>
											<div style={{ float: 'left' }}>
												{`Sent : ${translate(this.props.communication.title)}`}
											</div>
											{!this.props.onClick && (
												<div
													onClick={() =>
														this.setState({ open: !this.state.open })
													}
													style={{ float: 'right', cursor: 'pointer' }}
												>
													{this.state.open ? '-' : '+'}
												</div>
											)}
										</div>

										{/* LOG RENDERING */}
										<div
											style={{
												...this.style.logText,
												...this.mapColors(),
												display: this.state.open ? '' : 'none',
											}}
										>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div
													style={{
														...this.style.logTitleText,
														marginTop: 5,
														width: 85,
													}}
												>
													Recipient :
												</div>
												<div
													style={{
														...this.style.logBodyText,
														width: '100%',
														fontSize: 12,
														padding: 6,
														maxWidth: 'calc(100% - 75px)',
													}}
												>
													{this.props.communication.recipient}
												</div>
											</div>

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div
													style={{
														...this.style.logTitleText,
														marginTop: 5,
														width: 85,
													}}
												>
													Message :
												</div>
												<div
													style={{
														...this.style.logBodyText,
														width: '100%',
														fontSize: 12,
														padding: 6,
														marginBottom: 0,
														maxWidth: 'calc(100% - 75px)',
													}}
												>
													{/* Render the parsed and sanitized message */}
													{parse(sanitizedMessage)}

													{this.props.onClick && (
														<div
															onMouseEnter={this.handleHovered}
															onMouseLeave={this.handleHovered}
															onDoubleClick={this.handleSend}
															style={{
																cursor: 'pointer',
																position: 'relative',
																...this.mapColors(),
																height: 34,
																width: 34,
																borderRadius: 17,
																float: 'right',
																transform: 'translate(10px, 10px)',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															{!this.state.isBusy ? (
																<img
																	src={`/assets/button_send_neutral${
																		this.state.sendHovered ? '_hovered' : ''
																	}.svg`}
																	style={{ height: 30, width: 30 }}
																/>
															) : (
																<Lottie
																	options={{
																		animationData: require('../assets/loading.json'),
																	}}
																/>
															)}
														</div>
													)}
												</div>
											</div>
										</div>

										<div
											style={{
												...this.style.logText,
												...this.mapColors(),
												display: 'flex',
												flexDirection: 'row',
												marginTop: 5,
												borderRadius: 6,
												fontSize: 12,
												height: 40,
												padding: 0,
											}}
										>
											<p
												style={{
													fontSize: 14,
													fontWeight: 300,
													marginTop: 8,
													marginLeft: 10,
												}}
											>
												Delivery :
											</p>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginLeft: 20,
													marginRight: 10,
													marginTop: 7,
													height: 25,
												}}
											>
												<div
													style={{
														...this.style.logBodyText,
														height: 25,
														width: '100%',
														fontSize: 12,
														padding: 5,
														paddingTop: 4,
														color:
															this.props.communication.status === 'UNDELIVERED'
																? 'red'
																: '#68737E',
													}}
												>
													{this.normalizeStatus()}
												</div>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginLeft: 5,
													marginTop: 7,
													height: 25,
												}}
											>
												<div
													style={{
														...this.style.logBodyText,
														height: 25,
														width: '100%',
														fontSize: 12,
														padding: 5,
														paddingTop: 4,
													}}
												>
													{this.props.log
														? DateHelper.formatDate(
																this.props.log.modificationDate
														  )
														: '- - -'}
												</div>
											</div>
											{this.props.log && (
												<div
													style={{
														float: 'right',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														marginLeft: 'auto',
														marginRight: 10,
													}}
												>
													<PORButton log={this.props.log} />
													<DeliveryFileButton log={this.props.log} />
												</div>
											)}
										</div>
									</div>

									{/* BADGE RENDERING */}
									{this.renderBadge(this.props.log)}
								</div>
							)}
						</LocalizationContext.Consumer>
					);
				}}
			</Spring>
		);
	}

	renderStamp(log: any, companyLog?: any, userLog?: any) {
		if (log) {
			if (log.user) {
				return (
					<span style={this.style.basicStampRight}>
						{`${log.user.firstName} ${
							log.user.lastName
						} (Medicheck) ${DateHelper.formatDate(log.creationDate)}`}
					</span>
				);
			}
		}

		if (!log) {
			return <span style={this.style.basicStampRight}>{`...`}</span>;
		}

		if (userLog && companyLog) {
			return (
				<span style={this.style.basicStampRight}>
					{`${userLog} (${companyLog}) ${DateHelper.formatDate(
						log.creationDate
					)}`}
				</span>
			);
		}

		return (
			<span style={this.style.basicStampRight}>
				{`Valou System (Medicheck) ${DateHelper.formatDate(log.creationDate)}`}
			</span>
		);
	}

	renderBadge(log?: any) {
		if (log) {
			let user = log.user;

			if (!user) {
				user = {
					firstName: 'Valou',
					lastName: 'System',
				};
			}

			return (
				<div
					onMouseEnter={() => this.setState({ hovered: true })}
					onMouseLeave={() => this.setState({ hovered: false })}
					onClick={() => {
						this.props.handleRefreshCommunication();
						this.forceUpdate();
					}}
					style={{
						...this.style.badgeContainer,
						cursor: 'pointer',
						width: 40,
						height: 40,
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					{
						<Spring
							from={{ rotation: this.state.hovered ? 0 : 360 }}
							to={{ rotation: this.state.hovered ? 360 : 0 }}
						>
							{(style: any) => {
								if (style.rotation > 180) {
									return (
										<img
											src='/assets/button_reload.png'
											style={{
												height: 40,
												width: 40,
												transform: `rotate(${style.rotation}deg)`,
											}}
										/>
									);
								}

								return (
									<span
										style={{
											...this.style.badgeText,
											transform: `rotate(${style.rotation}deg)`,
										}}
									>{`${user.firstName.charAt(0)}${user.lastName.charAt(
										0
									)}`}</span>
								);
							}}
						</Spring>
					}
				</div>
			);
		}

		return (
			<div
				onMouseEnter={() => this.setState({ hovered: true })}
				onMouseLeave={() => this.setState({ hovered: false })}
				onClick={() =>
					this.props.handleDeleteCommunication(this.props.communication)
				}
				style={{
					...this.style.badgeContainer,
					...this.mapColors(),
					cursor: 'pointer',
					padding: this.state.hovered ? 0 : 10,
				}}
			>
				{this.state.hovered ? (
					<img
						src='/assets/button_delete_certif_hovered.png'
						style={{ height: 40, width: 40 }}
					/>
				) : (
					<img src='/assets/icon_user.svg' style={this.style.userIcon} />
				)}
			</div>
		);
	}
}
