import * as React from 'react';
import EmailViewStyle from './EmailViewStyle';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import EmailViewProps from './EmailViewProps';
import { Spring } from 'react-spring';
import Lottie from 'lottie-react-web';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import DateHelper from 'src/helpers/DateHelper';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export default class EmailView extends BaseComponent<EmailViewProps, any> {
	/* ATTRIBUTES */
	style = new EmailViewStyle();

	/* CONSTRUCTOR */
	constructor(props: any) {
		super(props);

		// Initial state
		this.state = {
			open: this.props.onClick !== undefined,
			isBusy: false,
			hovered: false,
			sendHovered: false,
		};

		// Bindings
		this.mapColors = this.mapColors.bind(this);
		this.renderBadge = this.renderBadge.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleHovered = this.handleHovered.bind(this);
	}

	/* HANDLERS */
	async handleSend() {
		const { onClick } = this.props;

		if (onClick && !this.state.isBusy) {
			onClick(this.props.communication);
			this.setState({ isBusy: true });
		}
	}

	handleHovered() {
		this.setState((previousState) => {
			return { sendHovered: !previousState.sendHovered };
		});
	}

	/* METHODS */
	mapColors() {
		const { targetType } = this.props.communication;

		switch (`${targetType}`) {
			case '0': // WORKER
				return { backgroundColor: '#FDB56D' };
			case '1': // COMPANY
				return { backgroundColor: '#9FD6E5' };
			default: // DOCTOR
				return { backgroundColor: '#85D8C8' };
		}
	}

	/* RENDER */
	render() {
		const fromAnimation = {
			marginLeft: window.innerWidth - 517 - 80,
			scale: 0,
		};
		const toAnimation = { marginLeft: 0, scale: 1 };

		// Sanitize and parse the HTML message
		const sanitizedMessage = this.props.communication
			? DOMPurify.sanitize(this.props.communication.message)
			: '';

		return (
			<Spring from={fromAnimation} to={toAnimation} delay={this.props.delay}>
				{(animatedStyle: any) => {
					return (
						<LocalizationContext.Consumer>
							{({ translate }) => (
								<div
									key={this.props.communication.id}
									style={{
										...this.style.basicLogContainer,
										transform: `translate(${
											animatedStyle.marginLeft
										}px, ${0}px)`,
									}}
								>
									<div style={this.style.basicLog}>
										{/* STAMP RENDERING */}
										{this.renderStamp(this.props.log)}

										<div
											style={{
												...this.style.commentLogText,
												...this.mapColors(),
												borderTopLeftRadius: 4,
												borderTopRightRadius: 4,
												height: 38,
												borderBottomLeftRadius: this.state.open ? 0 : 4,
												borderBottomRightRadius: this.state.open ? 0 : 4,
											}}
										>
											<div style={{ float: 'left' }}>
												{`Sent : ${translate(this.props.communication.title)}`}
											</div>
											{!this.props.onClick && (
												<div
													onClick={() =>
														this.setState({ open: !this.state.open })
													}
													style={{ float: 'right', cursor: 'pointer' }}
												>
													{this.state.open ? '-' : '+'}
												</div>
											)}
										</div>

										{/* LOG RENDERING */}
										<div
											style={{
												...this.style.logText,
												...this.mapColors(),
												display: this.state.open ? '' : 'none',
											}}
										>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div
													style={{
														...this.style.logTitleText,
														marginTop: 5,
														width: 85,
													}}
												>
													Recipient :
												</div>
												<div
													style={{
														...this.style.logBodyText,
														width: '100%',
														fontSize: 12,
														padding: 6,
														maxWidth: 'calc(100% - 75px)',
													}}
												>
													{this.props.communication.recipient}
												</div>
											</div>

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div
													style={{
														...this.style.logTitleText,
														marginTop: 5,
														width: 85,
													}}
												>
													Subject :
												</div>
												<div
													style={{
														...this.style.logBodyText,
														width: '100%',
														fontSize: 12,
														padding: 6,
														maxWidth: 'calc(100% - 75px)',
													}}
												>
													{this.props.communication.subject}
												</div>
											</div>

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div
													style={{
														...this.style.logTitleText,
														marginTop: 5,
														width: 85,
													}}
												>
													Message :
												</div>
												<div
													style={{
														...this.style.logBodyText,
														width: '100%',
														fontSize: 12,
														padding: 6,
														marginBottom: 0,
														overflowWrap: 'break-word',
														maxWidth: 'calc(100% - 75px)',
													}}
												>
													{/* Render the parsed and sanitized message */}
													{parse(sanitizedMessage)}

													{this.props.onClick && (
														<div
															onMouseEnter={this.handleHovered}
															onMouseLeave={this.handleHovered}
															onDoubleClick={this.handleSend}
															style={{
																cursor: 'pointer',
																position: 'relative',
																...this.mapColors(),
																height: 34,
																width: 34,
																borderRadius: 17,
																float: 'right',
																transform: 'translate(10px, 10px)',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															{!this.state.isBusy ? (
																<img
																	src={`/assets/button_send_neutral${
																		this.state.sendHovered ? '_hovered' : ''
																	}.svg`}
																	style={{ height: 30, width: 30 }}
																/>
															) : (
																<Lottie
																	options={{
																		animationData: require('../assets/loading.json'),
																	}}
																/>
															)}
														</div>
													)}
												</div>
											</div>
										</div>
									</div>

									{/* BADGE RENDERING */}
									{this.renderBadge(this.props.log)}
								</div>
							)}
						</LocalizationContext.Consumer>
					);
				}}
			</Spring>
		);
	}

	renderStamp(log: any, companyLog?: any, userLog?: any) {
		// Existing stamp rendering logic
		// ...
	}

	renderBadge(log?: any) {
		// Existing badge rendering logic
		// ...
	}
}
