import * as React from 'react';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import SMSLogViewStyle from './SMSLogViewStyle';
import DoctorCommunicationService from '../../../../../../services/DoctorCommunicationService';
import DoctorCommunicationTemplateService from '../../../../../../services/DoctorCommunicationTemplateService';
import Lottie from 'lottie-react-web';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export default class SMSLogView extends BaseComponent<any, any> {
	/* ATTRIBUTES */
	style = new SMSLogViewStyle();

	/* CONSTRUCTOR */
	constructor(props: any) {
		super(props);

		// Initial state
		this.state = {
			communication: undefined,
			isBusy: false,
			sendHovered: false,
		};

		// Bindings
		this.mapColors = this.mapColors.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleHovered = this.handleHovered.bind(this);
	}

	async componentDidMount() {
		await this.handleRefresh();
	}

	async componentWillReceiveProps(nextProps: any) {
		if (nextProps !== this.props) {
			await this.handleRefresh();
		}
	}

	/* HANDLERS */
	async handleSend() {
		if (!this.state.isBusy) {
			this.setState({ isBusy: true });
			await this.execute(
				new DoctorCommunicationService().post(
					this.props.check.id,
					this.props.doctor.id,
					this.state.communication
				)
			);
			this.success('SMS sent.');
			this.setState({ isBusy: false });
		}
	}

	handleHovered() {
		this.setState((previousState) => {
			return { sendHovered: !previousState.sendHovered };
		});
	}

	async handleRefresh() {
		const { isModify, isDelete } = this.props;
		let communication = undefined;
		let communications: any = [];
		const res = await this.execute(
			new DoctorCommunicationService().get(
				this.props.check.id,
				this.props.doctor.id
			)
		);

		if (res) {
			communications = res.filter((elt: any) => elt?.methodType === 1);
		}

		if (isModify) {
			communication = await this.execute(
				new DoctorCommunicationTemplateService().get(
					this.props.check.id,
					this.props.doctor.id,
					communications?.filter((elt: any) =>
						elt.title.includes('MODIFICATION')
					)[0].id
				)
			);
		} else if (isDelete) {
			communication = await this.execute(
				new DoctorCommunicationTemplateService().get(
					this.props.check.id,
					this.props.doctor.id,
					communications?.filter((elt: any) =>
						elt.title.includes('CANCELLATION')
					)[0].id
				)
			);
		} else {
			communication = await this.execute(
				new DoctorCommunicationTemplateService().get(
					this.props.check.id,
					this.props.doctor.id,
					communications?.filter((elt: any) =>
						elt.title.includes('APPOINTMENT')
					)[0].id
				)
			);
		}

		this.setState({ communication });
	}

	/* METHODS */
	mapColors() {
		return { backgroundColor: '#85D8C8' };
	}

	/* RENDER */
	render() {
		// Sanitize the message content
		const sanitizedMessage = this.state.communication
			? DOMPurify.sanitize(this.state.communication.message)
			: '';

		return (
			<>
				<div style={this.style.basicLog}>
					<div
						style={{
							...this.style.commentLogText,
							...this.mapColors(),
							borderTopLeftRadius: 0,
							borderTopRightRadius: 4,
							height: 38,
							borderBottomLeftRadius: 4,
							borderBottomRightRadius: 4,
						}}
					>
						<div style={{ float: 'left' }}>{'Doctor message by SMS'}</div>
					</div>

					{/* LOG RENDERING */}
					<div style={{ ...this.style.logText, ...this.mapColors() }}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div
								style={{ ...this.style.logTitleText, marginTop: 5, width: 85 }}
							>
								Recipient :
							</div>
							<div
								style={{
									...this.style.logBodyText,
									width: '100%',
									fontSize: 12,
									padding: 6,
									maxWidth: 'calc(100% - 75px)',
								}}
							>
								{this.state.communication
									? this.state.communication.recipient
									: ''}
							</div>
						</div>

						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div
								style={{ ...this.style.logTitleText, marginTop: 5, width: 85 }}
							>
								Message :
							</div>
							<div
								style={{
									...this.style.logBodyText,
									width: '100%',
									fontSize: 12,
									padding: 6,
									marginBottom: 0,
									maxWidth: 'calc(100% - 75px)',
								}}
							>
								{/* Render the parsed and sanitized message */}
								{parse(sanitizedMessage)}

								<div
									onMouseEnter={this.handleHovered}
									onMouseLeave={this.handleHovered}
									onDoubleClick={this.handleSend}
									style={{
										cursor: 'pointer',
										position: 'relative',
										...this.mapColors(),
										height: 34,
										width: 34,
										borderRadius: 17,
										float: 'right',
										transform: 'translate(10px, 10px)',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{!this.state.isBusy ? (
										<img
											src={`/assets/button_send_neutral${
												this.state.sendHovered ? '_hovered' : ''
											}.svg`}
											style={{ height: 30, width: 30 }}
										/>
									) : (
										<Lottie
											options={{
												animationData: require('../assets/loading.json'),
											}}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
